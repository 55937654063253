import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout"
import { CardBox, CardList, Genre, GenreList, SmallP } from "../../styles/Global.styled";
// import {RoastList} from "../../styles/Roasts.styled";
// import {CardBox, CardBoxImg, CardBoxTitle, SmallP} from "../../styles/Assets.styled";

export default function Tracks({ data }) {
  const tracks = data.allMarkdownRemark.nodes
  console.log("track list")
  console.log(tracks)
  return (
    <Layout title="Tracklist">
      <h1>Track list</h1>
      <div>
        <CardList>
          {tracks.map(track => {
            const tdate = new Date(track.frontmatter.releaseDate)
            const coverPic = track.frontmatter.cover
            const duration = new Date(0)
            duration.setSeconds(track.frontmatter.duration)
            const durationString = duration.getMinutes() + ":" + duration.getSeconds().toString().padStart(2, '0')
            const genres = track.frontmatter.genres
            const slug = track.fields.slug
            const trackLink = "/tracks" + slug

            return (
              <CardBox key={track.id}>
                <Link to={trackLink}>
                  <p>{track.frontmatter.title}</p>
                  <SmallP>Released: {tdate.toDateString()}</SmallP>
                  <SmallP>Duration: {durationString}</SmallP>
                  <GenreList>Genres</GenreList>
                  {
                    genres.map(genre => {
                      return (
                      <Genre>{genre}</Genre>
                      )
                    })
                  }
                </Link>
                <Link to={trackLink}>
                  <GatsbyImage alt="Cover" image={getImage(coverPic)} />
                </Link>
              </CardBox>
            )
          })}
        </CardList>
      </div>
    </Layout>
  )
}

export const allTracks = graphql`
  query getAllTracks {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "track" } } }
      sort: { fields: frontmatter___releaseDate, order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          type
          genres
          duration
          releaseDate
          description
          cover {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                width: 150
                formats: AUTO
              )
            }
          }
          author
          genres
        }
        id
        html
      }
    }
  }
`
